import Typography from '@material-ui/core/Typography';
import React from 'react';
import styles from './Price.module.css';


function Price(props) {

    function formatPrice(price) {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }


    return (
        <>
            { props.value !== null
            ? <Typography className={styles.Price} variant="caption">Ø {formatPrice(props.value)}€</Typography> 
            : <Typography className={styles.Price} variant="caption">no price</Typography>

            }
            
        </>
    )
}

export default Price;