import { createMuiTheme } from '@material-ui/core/styles'

const primaryColor = '#2F80ED'
const secondaryColor = '#E8EFF8'
const blackFont = '#212326'
const white = '#fff'

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
        'Nunito',
      ].join(','),
    color: blackFont,
    h2: {
      color: white,
      fontWeight: 800,
      letterSpacing: 1.05,
      lineHeight: 1.25,
    },
    h4: {
      fontSize: '1.75em',
      fontWeight: 700,
      lineHeight: '1.2em',
    },
    h5: {
      fontSize: '1.5em',
      fontWeight: 700,
      lineHeight: '1.2em',
      marginBottom: '0.1em',
    },
    h6: {
      fontSize: '1.1em',
      fontWeight: 700,
      lineHeight: '1.2em',
      marginBottom: '0.5em',
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '2em',
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '1.5em',
    },
    colorSecondary: {
      color: white,
    },
  },
  palette: {
    primary: {
      main: primaryColor,
      dark: primaryColor
    },
    secondary: {
        main: secondaryColor,
        contrastText: blackFont,
    },
    background: {
        paper: white,
        default: secondaryColor,
    },
    text:{
    },
    action:{
      active: primaryColor,
      disabled: secondaryColor
    }
  },
  shape: {
    borderRadius: 10
  },
  overrides: {
    MuiButton: {
        root: {
          fontWeight: '700',
          letterSpacing: 1.1,
          padding: '0.94em 1.25em 0.94em 1.25em',
          lineHeight: '1em'
        },
        textPrimary: {
            color: white,
            backgroundColor: primaryColor,
            padding: '0.94em 1.25em 0.94em 1.25em',
        },
        textSecondary: {
            color: primaryColor,
            backgroundColor: white,
            padding: '0.94em 1.25em 0.94em 1.25em',
        },
        contained: {
            backgroundColor: white,
            fontWeight: '700',
            "&:hover": {
              backgroundColor: white,
            },
        },
        containedPrimary:{
            color: white,
            backgroundColor: primaryColor
        },
        containedSecondary:{
            color: primaryColor,
            backgroundColor: white,
            "&:hover": {
              backgroundColor: white,
            }, 
        },
    },
    MuiChip: {
      root: {
        textTransform: 'uppercase',
        letterSpacing: '0.1em',
        fontWeight: 600,
        margin: '0.5em 1em 0em 0',
      },
      sizeSmall: {
        fontSize: '0.8em',
        fontWeight: 500,
        letterSpacing: 0,
        margin: '0 0.25em',
      },
      colorSecondary: {

      }
    },
    MuiToggleButtonGroup: {
      grouped: {
        fontWeight: '700',
        color: blackFont,
        backgroundColor: white,
      }
    },
    MuiToggleButton:{
        root: {
        },
        label: {
          padding: '0.15em 1.25em 0.15em 1.25em',
        }

    },
    MuiMenuItem: {
      root: {
        fontSize: '1em',
        fontWeight: '700',
        textTransform: 'uppercase',
        letterSpacing: 1.1,
        padding: '0.94em 1.25em 0.94em 1.25em',
        lineHeight: '1em',
      }
    },
    MuiCard:{
      root:{
        border: 'none',
        filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.05))',
      }
    },
    MuiCardMedia:{
      root: {
      }
    }
  }
});

export default theme;