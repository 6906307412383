import React from 'react';

import AddIcon from '@material-ui/icons/Add'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { Button } from '@material-ui/core'

import styles from './ButtonIcon.module.css'

function ButtonIcon(props) {
    const icon = () => {
        switch(props.icon) {
  
          case "AddIcon":       return <AddIcon className={styles.Icon} />;
          case "SortIcon":      return <ArrowDropDownIcon className={styles.Icon}/>;
          case "CloseIcon":     return <AddIcon className={styles.Icon}/>;
  
          default:              return <span>Icon Missing</span>
        }
      }


    return (
        <Button {...props}>
            {props.children} { icon() }
        </Button>
    );
}

export default ButtonIcon;