import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ButtonIcon from './toggleButtons/buttonIcon/ButtonIcon';

export default function FilterMenuButton(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const optionList = props.options


    return (
        <>
            <ButtonIcon aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} variant="contained" color={props.color} disableElevation icon="SortIcon" >{props.children}</ButtonIcon>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                >
                {optionList.map((option, index) => (
                    <MenuItem onClick={handleClose} key={index}> {option} </MenuItem>
                ))}
            </Menu>
        </>
    );
}