import React from 'react'
import Star from './Star'

import styles from './Stars.module.css'

function Stars(props) {
    const dataRating = props.rating
    const rating = Math.round(dataRating*2) / 2;

    const stars = []
    

    let starsRendered = 0;
    for (let index = 0; index < 5; index++) {
        if ((starsRendered + 1) <= rating) {
            stars.push(<Star type="Full" className={styles.Star} key={index}/>)
        } else if ((starsRendered + 1) > rating && (starsRendered+0.5) === rating) {
            stars.push(<Star type="Half" className={styles.Star} key={index}/>)
        } else {
            stars.push(<Star type="Empty" className={styles.Star} key={index} />)
        }
        starsRendered ++;
    }


    return (
        <div className={styles.Stars}>
            {stars}
        </div>
    )

}

export default Stars;