import React from 'react'
import sailIcon from '../../../assets/sailboat_icon.png'
import styles from './LogoIcon.module.css'


function LogoIcon(props) {
    return (
        <div className={styles.LogoIcon}>
            <img src={sailIcon} alt="Logo" />
        </div>
    );
}

export default LogoIcon;