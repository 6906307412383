import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Airtable from 'airtable'
import { Typography } from '@material-ui/core'
import BoatCard from '../components/boatCard/BoatCard'

import styles from './BoatGrid.module.css'
import AdsCard from '../components/adsCard/AdsCard'
import AdsSection from '../components/adsSection/AdsSection'


//Airtable
const base = new Airtable({apiKey:process.env.REACT_APP_AIRTABLE_API_KEY}).base('appu8DCr1xCEe0TWa')


class BoatGrid extends Component {
	constructor(props) {
		super(props);
		this.state = {
			boatData: []
		};
	}
	componentDidMount() {

		base('boats').select({view: "All Data", maxRecords: 30, pageSize: 30})
		.eachPage(
			(boatData, fetchNextPage) => {
				this.setState({
					boatData
				  });
				fetchNextPage();
			}
		);
	}

	render() {
		return (
			<>
				<Grid container spacing={5} className={styles.Grid}>
					{ this.state.boatData.length > 0 ? ( this.state.boatData.map((boat) => 
						<BoatCard
							{...boat.fields}
							key={boat.fields.id} />
					) ) : (
						<Typography> Loading... </Typography>				
					) }
					<AdsSection />
					<AdsCard />
				</Grid>
			</>
		)
	}
}

export default BoatGrid;