import React from 'react'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import HeartIcon from '../elements/heart/HeartIcon.js'
import Stars from '../elements/stars/Stars.js'
import Price from '../elements/price/Price.js'

import styles from './BoatCard.module.css'
import { Box } from '@material-ui/core'
import Tags from '../elements/tags/Tags.js'

const BoatCard = (props) => {
	
	const list = []
	Array.prototype.push.apply(list, props.tags_list)

	function checkNum(prop, backup) {
		if (Number.isInteger(prop)){
			return prop
		} else {
			return backup
		}
	}
	function check(prop, backup) {
		if (prop !== undefined){
			return prop
		} else {
			return backup
		}
	}

	return (
		<>
			<Grid item xs={12} sm={6} md={4} lg={3}>
				<Card variant="outlined" boxshadow={2}>
					<CardMedia image={'https://www.bavariayachts.com/fileadmin/_processed_/5/e/csm_bavaria-home-map-sy_f460b1db98.jpg'} title='React' className={styles.Media}/>
					<div className={styles.HoverCard} >
						<div className={styles.TopRow}>
							<div className={styles.Stars}><Stars rating={props.rating} /></div>
							<div className={styles.Heart}><HeartIcon /></div>
						</div>
						<div className={styles.Price} color="secondary"><Price value={checkNum(props.average_price, null)}/></div>
					</div>
					<CardActionArea>
						<CardContent>
							<Typography variant='h5' component='h4'>
								{props.title}
							</Typography>
							<Typography variant='h6' color='textSecondary' component='h5'>
								by {check(props.make_names, 'make unknown')}
							</Typography>
							
							<Box color='textSecondary' className={styles.InnerCard}>
								<Grid container>
									<Grid item xs={6}>
										<Typography component="div">
											Length: {props.length} m
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography component="div">
											Draft: {props.draft} m
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography component="div">
											Weight: {props.weight} t
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography component="div">
											Year: {props.years}
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography component="div">
											Type: <Tags tags={list} color='secondary' size='small' />
										</Typography>
									</Grid>
								</Grid>
							</Box>
						</CardContent>
					</CardActionArea>
				</Card>
			</Grid>
		</>
	)
};

export default BoatCard

