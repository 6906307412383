import React from 'react'
import { Button, Typography, Card, CardMedia, CardContent } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'


import Herocardimage from '../../../../assets/hero_card.png'
import Nav from '../../../nav/Nav'
import Waves from '../../../elements/waves/Waves'

import styles from './Hero.module.css'
import Emoji from '../../../elements/Emoji'
import Tags from '../../../elements/tags/Tags'

function Hero(props) {

    const handleClick = () => {
        console.info('You clicked the Chip.');
      };
    
    
    const list = ['Cruising ', 'Catamarans','Blue water', 'small', 'Luxury', 'New', 'Old Classics']
    // Array.prototype.push.apply(list, props.tags_list_hero)

    return (
        <div className={styles.Hero}>
            <Nav />
            <Container maxWidth="lg">
                <div className={styles.HeroContent}>
                    <div>
                        <Typography variant='h2' component='h1'>Find your perfect sailboat!<Emoji symbol="⛵️"/></Typography>
                        <Typography gutterBottom variant='subtitle1' color="secondary">Join our worldwide community and meet passionate sailors.</Typography>
                        <Tags onClick={handleClick} color="secondary" tags={list} />
                    </div>
                    <Hidden smDown>
                    <Card className={styles.HeroCard} square={false}>
                        <CardMedia image={Herocardimage} title='Community' className={styles.Media}  />
                        <CardContent className='PrimaryCard'>
                            <Typography variant='h4' component='h3' color="secondary">Join our worldwide community and meet passionate sailors.</Typography>
                            <Button variant="contained" disableElevation color="secondary">Join Now</Button>
                        </CardContent>
                    </Card>
                    </Hidden>
                </div>       
            </Container>
            <Waves />
        </div>
    );
}

export default Hero;