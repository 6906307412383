import { Chip } from '@material-ui/core';
import React from 'react';

function Tag(props) {
    return (
        <Chip
            variant={props.variant}
            label={props.label}
            color={props.color}
            size={props.size}
        >
            {props.children}
        </Chip>
    );
}

export default Tag;