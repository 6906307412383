import React from 'react';

import styles from './Waves.module.css'

function Waves(props) {
    return (
        <svg className={styles.Wave}  viewBox="0 0 1440 99" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M1440 99V15.7883C1377.44 26.4006 1314.88 37.0128 1250 34.0263C1185.12 31.0397 1117.92 14.4543 1064 23.0835C1010.08 31.7127 969.44 65.5565 924 83.6335C878.56 101.711 828.321 104.021 752 84.3631C675.679 64.7051 573.275 23.0789 507 30.3787C440.725 37.6784 410.58 93.9042 353 93.1173C295.42 92.3304 210.406 34.531 147 12.1407C83.5942 -10.2496 41.7971 2.76935 0 15.7883V99H1440Z" fill="#E8EFF8"/>
        </svg>
    );
}

export default Waves;