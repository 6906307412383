import React from 'react';

import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarHalfIcon from '@material-ui/icons/StarHalf';

function Star(props) {
    const star = () => {
        switch(props.type) {
  
          case "Full":       return <StarIcon style={{ color: '#fff' }} />;
          case "Half":      return <StarHalfIcon style={{ color: '#fff' }} />;
          case "Empty":     return <StarBorderIcon style={{ color: '#fff' }} />;
  
          default:              return <span>Icon Missing</span>
        }
      }

    return (
        <>
            { star() }
        </>
    );
}

export default Star;