import React from 'react'
import Container from '@material-ui/core/Container'

import Hero from './hero/Hero'
import Footer from '../../footer/Footer'
import BoatGrid from '../../../containers/BoatGrid'
import Filters from '../../filters/Filters'

import styles from './Home.module.css'


const Home = (props) => {
    return (
        <>
            <Hero/>
            <main>
                <Container className={styles.Container} maxWidth="lg">
                    <Filters />
                    <BoatGrid />
                </Container>
            </main>
            <Footer></Footer>
        </>
    )
};


export default Home