import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import styles from './AdsSection.module.css'

const AdsSection = () => {
	return (
		<>
			<Grid item xs={12}>
				<Card variant="outlined" className={styles.Section} boxshadow={2}>
					<CardContent className='PrimaryCard'>
						<Typography variant='h4' component='h2'>
							Join our worldwide community of passionate sailors 
						</Typography>
						<Typography gutterBottom variant='subtitle2' color="secondary">
							Become part of our community to connect, interact and meet new sailors from all over the world.
						</Typography>
						<Button variant="contained" disableElevation color="secondary">Join Now</Button>
					</CardContent>
				</Card>
			</Grid>
		</>
	)
};

export default AdsSection

