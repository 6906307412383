import React from 'react'
import { Button } from '@material-ui/core'
import Container from '@material-ui/core/Container'

import Logo from '../elements/logo/Logo'

import styles from './Nav.module.css'

function Nav(props) {

    return (
        <nav >
            <Container maxWidth="lg">
                <div className={styles.Innernav}>
                    <Logo></Logo>
                    <div className={styles.navElements}>
                        
                        <Button className={styles.navBtt} variant="contained" disableElevation color="primary" >Sign up</Button>
                    </div>  
                </div>
            </Container>
        </nav>
    );
}

export default Nav;