import React from 'react'
import { fade, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'

import FilterMenuButton from '../elements/buttons/FilterMenuButton'
import ButtonIcon from '../elements/buttons/toggleButtons/buttonIcon/ButtonIcon'
import Emoji from '../elements/Emoji'


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    search: {
      fontSize: '14px',
      lineHeight: 1,
      position: 'relative',
      display: 'inline-block',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 1),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.8),
      },
      marginLeft: 0,
      marginRight: '1rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1.3, 1, 1.5, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
    FilterBtt: {
        marginRight: '1rem',
    }
  }))

export default function Filters() {
    const classes = useStyles();

    return (
      <Grid
        container
        justify="space-between"
        alignItems="center"
      >
        <Box>
          <Button variant="contained" disableElevation color="primary" className={classes.FilterBtt}>
            All Filter
          </Button>
          <ButtonIcon variant="contained" disableElevation icon="AddIcon" className={classes.FilterBtt}>
            Add Filter
          </ButtonIcon>
        </Box>
        <Box>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
          <FilterMenuButton
            options={["Average used Price", "Maximum used Price", "Minimum used Price"]}
          ><Emoji symbol="💰"/> &nbsp; Price</FilterMenuButton>
        </Box>
      </Grid>
    );
}