import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import AddCircleIcon from '@material-ui/icons/AddCircle';

import styles from './AdsCard.module.css'

const AdsCard = () => {
	return (
		<>
			<Grid item xs={12} sm={6} md={4} lg={3}>
				<Card variant="outlined" className={styles.Card} boxshadow={2}>
					<CardContent className="PrimaryCard" >
						<AddCircleIcon className={styles.Icon} />
						<Typography variant='h4' component='h2'>
							Add your own Boat
						</Typography>
						<Button variant="contained" disableElevation color="secondary">Add Now</Button>
					</CardContent>
				</Card>
			</Grid>
		</>
	)
};

export default AdsCard

