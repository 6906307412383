import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper';

import SimpleBreadcrumb from '../elements/SimpleBreadcrumb'

import styles from './Footer.module.css'

export default function Footer() {

  return (
    <footer>
      <Paper elevation={2} square>
        <Container className={styles.Footer} maxWidth="lg">
          <SimpleBreadcrumb />
          <Typography variant="overline" >made in Berlin with Coffee & Heart</Typography>
        </Container>
      </Paper>
    </footer>
  );
}