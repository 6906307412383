import React from 'react'
import Tag from './Tag';


function Tags(props) {
    const tagList = props.tags.map((tag, index) =>
        <Tag label={tag} color={props.color} size={props.size} key={index} />
	);

    return (
        <>
            {tagList}
        </>
    );
}

export default Tags;