import React from 'react'
import LogoIcon from './LogoIcon'
import styles from './Logo.module.css'

function Logo(props) {
    return (
        <div className={styles.Logo}>
            <LogoIcon />
            <span>Boat<b>Base.io</b></span>
        </div>
    );
}

export default Logo;